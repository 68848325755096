<template>
  <div>
    <v-card>
      <v-card-title class="align-start">
        <v-row>
          <v-col>
            <div class="d-flex">
              <span class="font-weight-semibold text-h6 text--white">EForm</span>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="ms-1" v-bind="attrs" v-on="on">
                    {{ icons.mdiInformation }}
                  </v-icon>
                </template>
                <div>
                  <span>{{ $t('eform.notFoundAction') }} </span>
                </div>
                <div class="mb-3">
                  <span>{{ $t('eform.notFoundAction2') }}.</span>
                </div>
              </v-tooltip>
            </div>
          </v-col>
        </v-row>
      </v-card-title>
      <!-- addBroadcast Dialog -->
      <v-dialog v-model="addBroadcastDialog" width="1000" scrollable @keydown.esc="closeDialogBroadcast">
        <template v-slot:default="">
          <v-card elevation="0">
            <v-toolbar color="primary">
              <div class="text-h5 white--text">
                {{ $t('broadcasts.createBroadcast') }}
              </div>
              <v-spacer></v-spacer>
              <v-btn class="pa-8" icon @click="closeDialogBroadcast()">
                <v-icon x-large color="white">
                  {{ icons.mdiCloseCircle }}
                </v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <add-broadcast
                :key="newBroadcast"
                :data-contacts-from-api="dataContactsFromApi"
                @close-dialog="closeDialog()"
              ></add-broadcast>
            </v-card-text>
          </v-card>
        </template>
      </v-dialog>
      <!--
        \\\\\\\\\\\\\\\\\\\\\\\\\\\
       -->
      <v-card-text>
        <v-row class="mt-3">
          <v-col cols="12" md="4">
            <div class="d-flex">
              <v-text-field
                v-model="search"
                :append-icon="icons.mdiMagnify"
                :label="$t('search')"
                single-line
                hide-details
                dense
                outlined
                class="me-2"
              ></v-text-field>
              <v-tooltip v-if="statusChannelsBanner && bannerAlertText" bottom color="warning" class="my-auto">
                <template #activator="{ on, attrs }">
                  <v-icon v-bind="attrs" color="warning" v-on="on">
                    {{ icons.mdiAlert }}
                  </v-icon>
                </template>
                <span>{{ bannerAlertText }}</span>
              </v-tooltip>
            </div>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" md="4">
            <v-btn block color="primary" :to="{ name: 'e-form-create', params: { fromCustomerGroup: false } }">
              <v-icon dark left>
                {{ icons.mdiPlus }}
              </v-icon>
              {{ $t('add') }} {{ $t('E-form') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-data-table
        item-key="id"
        :headers="columnsEform"
        :search="search"
        :items="eFormFiltered"
        class="text-no-wrap"
        :footer-props="{
          'items-per-page-text': $t('rowsPerPageLabel'),
        }"
      >
        <template slot="no-data">
          <v-row align="center" class="py-2 px-14">
            <v-col class="text-center" lg="6" md="6" sm="12" cols="12">
              <div class="text-start hero-landing">
                <h1 class="hero">
                  {{ $t('eform.notFound') }}
                </h1>
                <div>
                  <span>{{ $t('eform.notFoundAction') }}</span>
                </div>
                <div class="mb-3">
                  <span>{{ $t('eform.notFoundAction2') }}</span>
                </div>
                <!-- <div class="my-3 mobile-tag">
                    <div>
                      <span class="text--primary font-weight-semibold">{{ $t('contacts.hero.easiestWay') }},</span>
                    </div>
                    <div>
                      <span class="text--primary font-weight-semibold">{{ $t('contacts.hero.moreSales') }}, </span>
                    </div>
                    <div>
                      <span> {{ $t('contacts.hero.isByAdding') }}</span>
                    </div>
                    <div>
                      <span class="text--primary font-weight-semibold">  {{ $t('contacts.hero.here') }}.</span>
                    </div>
                  </div> -->
                <div class="justify-center try-btn">
                  <v-btn elevation="2" class="button-goLogin" color="primary" :to="{ name: 'e-form-create' }">
                    <span style="color: white">{{ $t('eform.add') }}</span>

                    <v-icon color="white">
                      {{ icons.mdiChevronRight }}
                    </v-icon>
                  </v-btn>
                </div>
              </div>
            </v-col>
            <v-col lg="6" md="6" sm="12" cols="12" class="hide-lottie">
              <div class="mx-auto mt-4 mb-4">
                <img class="img-eform" src="@/assets/images/IC_EForm.png" />
              </div>
            </v-col>
          </v-row>
        </template>
        <template #[`item.created_at`]="{ item }">
          {{ $moment(item.created_at).format('DD-MM-YYYY') }}
        </template>
        <template #[`item.updated_at`]="{ item }">
          {{ $moment(item.updated_at).format('DD-MM-YYYY') }}
        </template>
        <template #[`item.action`]="{ item }">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn v-bind="attrs" icon v-on="on" @click="goToStatistic(item.contactList)">
                <v-icon>{{ icons.mdiChartMultiple }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('eform.openStatistic') }}</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn v-bind="attrs" icon v-on="on" @click="editEform(item)">
                <v-icon>{{ icons.mdiPencil }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('eform.editEform') }}</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn v-bind="attrs" icon v-on="on" @click="openConfirmDeleteMessage(item)">
                <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('eform.deleteEform') }}</span>
          </v-tooltip>

          <!-- <v-btn
            icon
          >
            <v-icon>
              {{ icons.mdiChartMultiple }}
            </v-icon>
          </v-btn> -->
          <!-- <v-btn
            icon
          >
            <v-icon>
              {{ icons.mdiPencil }}
            </v-icon>
          </v-btn> -->
          <!-- <v-btn
            icon
          >
            <v-icon>
              {{ icons.mdiDeleteOutline }}
            </v-icon>
          </v-btn> -->
        </template>
        <template #[`item.contact`]="{ item }">
          <v-btn color="primary" block dense @click="goToContacts(item.contactList)">
            <v-icon dark left>
              {{ icons.mdiEye }}
            </v-icon>
            {{ $t('eform.viewContact') }}
          </v-btn>
        </template>
        <template #[`item.broadcast`]="{ item }">
          <v-btn color="primary" block dense @click="LinkBroadCast(item._id)">
            <v-icon dark left>
              {{ icons.mdiBroadcast }}
            </v-icon>
            {{ $t('eform.broadcastForm') }}
          </v-btn>
        </template>
        <template #[`item.title`]="{ item }">
          <a target="_blank" :href="`${linkData}/e-form/survey/${item._id}`">{{ item.title }}</a>

          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn v-bind="attrs" icon v-on="on" @click="copySurvey(item)">
                <v-icon>{{ icons.mdiContentCopy }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('eform.copy') }}</span>
          </v-tooltip>

          <!-- <v-btn
            icon
          >
            <v-icon>
              {{ icons.mdiContentCopy }}
            </v-icon>
          </v-btn> -->
        </template>
        <template #[`item.addToSurvey`]="{ item }">
          <v-checkbox v-model="item.addToSurvey" color="primary" @click="updateChoice(item)"></v-checkbox>
        </template>
        <template #[`item.selectedFields`]="{ item }">
          <!-- <v-checkbox v-model="item.addToSurvey"></v-checkbox> -->
          <span v-if="item.selectedFields.length === 0">All Fields</span>
          <span v-if="item.selectedFields.length !== 0">{{ item.selectedFields.join(', ') }}</span>
        </template>
        <!-- <template v-slot:item.action="{ item, index }">
        <v-btn
          icon
          @click.stop="deleteLabel(item, index)"
        >
          <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
        </v-btn>
      </template>
      <template #[`item.label`]="{ item }">
        <v-icon
          dark
          :style="{ color: item.label.color }"
        >
          {{ icons.mdiLabel }}
        </v-icon>
        {{ getLabelName(item.label.id) }}
      </template>
      <template #[`item.last_message`]="{ item }">
        {{ item.last_message !== undefined ? $moment.unix(item.last_message).format() : '' }}
      </template>
      <template #[`item.phone_number_show`]="{ item }">
        <div
          class="d-flex align-center"
          style="cursor: pointer"
          @click="gotoInbox(item)"
        >
          <v-avatar size="40">
            <v-img
              :src="getProfPic(item.profile_picture, item)"
              height="40"
              width="40"
            >
            </v-img>
          </v-avatar>
          <div class="d-flex flex-column ms-3">
            <span class="d-block font-weight-semibold text-truncate text--primary">{{ item.name }}</span>
            <span
              v-if="item.phone_number_show"
              class="text-xs"
            >{{
              item.phone_number_show.length > 16 ? 'Group Chat' : item.phone_number_show
            }}</span>
          </div>
        </div>
      </template>
      <template #[`item.chat`]="{ item }">
        <v-icon
          v-if="item.chat"
          @click="viewChat(item.chat)"
        >
          {{ icons.mdiEyeOutline }}
        </v-icon>
      </template> -->
      </v-data-table>
      <v-snackbar
        v-model="showSnackbarCopiedSuccessfully"
        color="success"
        :timeout="2000"
        :top="true"
        style="z-index: 5 !important"
      >
        {{ $t('LinkGenerator.copySuccess') }}
        <template #action="{ attrs }">
          <v-btn text v-bind="attrs" @click="closeShowSnackbarCopiedSuccessfully()">
            {{ $t('close') }}
          </v-btn>
        </template>
      </v-snackbar>

      <v-snackbar v-model="showSnackbar" color="error" :timeout="2000" :top="true" style="z-index: 5 !important">
        Contact List has been deleted
        <template #action="{ attrs }">
          <v-btn text v-bind="attrs" @click="showSnackbar = false">
            {{ $t('close') }}
          </v-btn>
        </template>
      </v-snackbar>
      <v-dialog v-model="confirmDeleteMessage" persistent width="600px">
        <v-card>
          <v-card-actions>
            <h3 class="font-weight-bold">
              {{ 'Warning!!!' }}
            </h3>
            <v-spacer></v-spacer>
          </v-card-actions>
          <v-divider />

          <v-card-text>
            <span style="font-weight: bold">{{ $t('eform.deleteEformConfirm') }}</span>
            <span>{{ $t('eform.deleteEformConfirm2') }}</span>
          </v-card-text>
          <v-divider class="" />
          <v-card-actions class="justify-end text-end">
            <v-spacer></v-spacer>
            <v-btn color="#ffeeee" class="mr-3" @click="closeConfirmDeleteMessage()">
              <span style="" class="error--text size-button-action">{{ $t('cancel') }}</span>
            </v-btn>
            <v-btn color="primary" class="mr-3" @click="deleteEformandContactList(deleteData, deleteDataContact)">
              <span style="" class="size-button-action">{{ $t('EOrdering.fullDelete') }}</span>
            </v-btn>
            <v-btn color="success" class="" @click="deleteEform(deleteData)">
              <span style="" class="size-button-action">OK</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>
<script>
import eFormMixin from '@/mixins/eFormMixin'
import PouchDB from '@/mixins/pouchDbMixin'
import {
  mdiAlert,
  mdiBroadcast,
  mdiChartMultiple,
  mdiCloseCircle,
  mdiContentCopy,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiEye,
  mdiFormSelect,
  mdiInformation,
  mdiPencil,
  mdiPlus,
} from '@mdi/js'
import Vue from 'vue'
import VueClipboard from 'vue-clipboard2'
import AddBroadcast from '../broadcast/AddBroadcast.vue'

Vue.use(VueClipboard)

export default {
  name: 'EForm',
  components: { AddBroadcast },
  mixins: [eFormMixin, PouchDB],

  setup() {
    return {
      icons: {
        mdiInformation,
        mdiFormSelect,
        mdiDotsVertical,
        mdiPlus,
        mdiCloseCircle,
        mdiPencil,
        mdiDeleteOutline,
        mdiBroadcast,
        mdiEye,
        mdiContentCopy,
        mdiChartMultiple,
        mdiAlert,
      },
    }
  },

  data() {
    return {
      showSnackbar: false,
      showSnackbarCopiedSuccessfully: false,
      linkData: process.env.VUE_APP_URL_EBUSSINESS,
      confirmDeleteMessage: false,
      deleteData: '',
      deleteDataContact: '',
      search: '',
      eForm: [],

      // broadcast
      dataContactsFromApi: null,
      newBroadcast: 1,
      addBroadcastDialog: false,
      url: '',
    }
  },
  computed: {
    bannerAlertText() {
      return this.$store.getters['inbox/getBannerAlertText']
    },
    statusChannelsBanner() {
      return this.$store.getters['global/getStatusChannels']
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
    allSettings() {
      return this.$store.getters['global/getSettings']
    },
    dataListContact2() {
      return this.listContactValue
    },
    columnsEform() {
      return [
        { text: this.$t('eform.action'), value: 'action', align: 'center' },
        { text: this.$t('eform.title'), value: 'title', align: 'center' },
        { text: this.$t('eform.description'), value: 'description', align: 'center' },
        { text: this.$t('eform.answerCount'), value: 'answerCount', align: 'center' },
        { text: this.$t('eform.selectedFields'), value: 'selectedFields', align: 'center' },
        { text: this.$t('eform.addToSurvey'), value: 'addToSurvey', align: 'center' },
        { text: this.$t('eform.createdAt'), value: 'created_at', align: 'center' },
        { text: this.$t('eform.updatedAt'), value: 'updated_at', align: 'center' },
        { text: this.$t('eform.contact'), value: 'contact', align: 'center' },
        { text: this.$t('eform.broadcast'), value: 'broadcast', align: 'center' },
      ]
    },
    eFormFiltered() {
      return this.eForm.filter(eform => eform.isFromCustomerGroup !== true)
    }
  },

  async mounted() {
    await this.getEForm()
    this.$store
      .dispatch('broadcast/getContacts', {
        subId: this.user.sub_id,
        token: this.user.token,
      })
      .then(response => {
        //console.log(response, ' == response')

        // this.isLoadContactsDone = true
        this.dataContactsFromApi = response
      })
      .catch(err => {
        console.log(err, ' == error')
      })
  },

  methods: {
    async updateChoice(form) {
      // //console.log(form, 'data');
      await this.updateSurveyStatus({ id: form._id, status: form.addToSurvey })
    },
    closeShowSnackbarCopiedSuccessfully() {
      this.showSnackbarCopiedSuccessfully = false
    },
    closeConfirmDeleteMessage() {
      this.confirmDeleteMessage = false
      this.deleteData = ''
      this.deleteDataContact = ''
    },
    openConfirmDeleteMessage(itemDeleted) {
      this.confirmDeleteMessage = true
      this.deleteData = itemDeleted._id
      this.deleteDataContact = itemDeleted.contactList
    },
    closeDialogBroadcast() {
      this.addBroadcastDialog = false
      this.$store.dispatch('link/sendLinkToBroadcast', '')
    },
    closeDialog() {
      this.addBroadcastDialog = false
      this.editBroadcastDialog = false
    },
    LinkBroadCast(link) {
      this.$store.dispatch('link/sendLinkToBroadcast', `${process.env.VUE_APP_URL_EBUSSINESS}/e-form/survey/${link}`)

      this.addBroadcastDialog = true
      this.newBroadcast += 1
    },
    async goToContacts(item) {
      // //console.log(item)
      const data = await this.$store.dispatch('eOrdering/getContactListCheck', { contact: item })
      if (data.status) {
        this.$router.push({ name: 'contacts-list-custom', params: { idlist: item } })
      } else {
        this.showSnackbar = true
      }
    },
    goToStatistic(item) {
      // //console.log(item)
      this.$store.dispatch('link/saveopenStatisticEform', true)

      // this.$store.dispatch('link/saveDataEform', item)
      // //console.log(this.$store.getters['link/getopenStatisticEform'], 'aikpsjaikopsnais')
      this.$router.push({ name: 'contacts-list-custom', params: { idlist: item } })
    },
    openSurvey(survey) {
      const url = `${process.env.VUE_APP_URL_EBUSSINESS}/e-form/survey/${survey._id}`
      window.open(url, '_blank')
    },
    async copySurvey(survey) {
      const url = `${process.env.VUE_APP_URL_EBUSSINESS}/e-form/survey/${survey._id}`

      try {
        await navigator.clipboard.writeText(url)
        this.showSnackbarCopiedSuccessfully = true
      } catch ($e) {
        //console.log(e, 'can not copy')
      }
    },
    openAnalytics(survey) {
      this.$router.push({ name: 'e-form-analytics', params: { id: survey } })
    },
    async deleteEform(eform_id) {
      await this.deleteEformDb(eform_id)

      // deleteEformDbandContactList
      this.confirmDeleteMessage = false
    },
    async deleteEformandContactList(eform_id, contactList) {
      const obj = {
        eform_id,
        contactList,
      }
      await this.deleteEformDbandContactList(obj)
      this.deleteData = ''
      this.deleteDataContact = ''

      // deleteEformDbandContactList
      this.confirmDeleteMessage = false
    },
    editEform(item) {
      this.$store.dispatch('link/saveDataEform', item)
      this.$router.push({ name: 'e-form-edit' })
    },
  },
}
</script>

<style scoped>
.padding-hero {
  margin-left: 80px;
  margin-right: 80px;
}

.img-eform {
  width: 60%;
}

.mobile-tag {
  display: none;
}
/* Mobile */
.size-button-action {
  font-size: 14px;
}

@media (max-width: 536px) {
  .hero-landing {
    justify-content: center;
    text-align: center;
  }
  .mobile-tag {
    display: block;
  }
  .hide-lottie {
    display: none;
  }
  .size-button-action {
    font-size: 10px;
  }
  /* .hero-subtitle {
    width: 200px;
  } */
}
</style>
